import React from 'react'
import Contact from "../components/Contact/Contact"
import NavBar2 from "../components/navbar/NavBar"



const ContactPage = () => {
    return (
        <>
        <NavBar2 />
     
        <Contact />
        
        </>
    )
}

export default ContactPage
